<template>
    <AgeConcent @age-confirmed="onAgeConfirmed" />
    <!-- App content -->
    <div v-if="ageVerified">
        <Preloader />
        <router-view :key="$route.fullPath" />
    </div>
</template>

<script>
import Preloader from './components/utilities/Preloader';
import AgeConcent from './components/ageConcent/AgeConcent';

export default {
    components: {
        Preloader,
        AgeConcent
    },
    data() {
        return {
            ageVerified: localStorage.getItem('ageVerified') === 'true', // Check age verification status
        };
    },
    methods: {
        onAgeConfirmed() {
            this.ageVerified = true; // Set the flag when age is confirmed
        },
    },
};
</script>