<template>
    <router-link :key="$route.fullPath" :to="{ name: 'single.video', params: { videoId: video.id } }"
        class="text-decoration-none text-dark ">
        <!--Card-->
        <div class="card mb-3">
            <div class="card-body text-center">
                <div class="position-relative">
                    <img :src="video.imageUrl" style="max-height: 200px;" :alt="video.title" data-holder-rendered="true"
                        class="img-fluid">
                    <span class="duration text-muted position-absolute bottom-0 end-0 m-1">{{ video.duration }}</span>
                </div>
                <h4 class="card-title mt-1 font-size-16">{{ truncate(video.title, 60, '...') }}</h4>
            </div>
        </div>
    </router-link>

</template>

<script>
export default {
    props: {
        video: Object,
    },
    methods: {
        truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
    }
}
</script>
