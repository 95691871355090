<!-- src/components/Preloader.vue -->
<template>
    <div>
        <!-- Fullscreen Bootstrap Modal -->
        <div class="modal fade" id="ageConfirmationModal" tabindex="-1" aria-labelledby="ageConfirmationModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-fullscreen ">
                <div class="modal-content">
                    <div class="modal-header ">
                        <h5 class="modal-title" id="ageConfirmationModalLabel">Verificare Vârstă</h5>
                    </div>
                    <div class="modal-body ">
                        <p class="lead">
                            Această aplicație conține conținut pentru adulți. Trebuie să aveți 18 ani sau mai mult
                            pentru a continua.
                        </p>
                        <div>
                            <button class="btn btn-primary btn-lg" @click="confirmAge">
                                Am 18 ani sau mai mult
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
export default {
    methods: {
        confirmAge() {
            localStorage.setItem('ageVerified', true); // Store the user's choice
            const modalElement = document.getElementById('ageConfirmationModal');
            const modalInstance = bootstrap.Modal.getInstance(modalElement); // Get the modal instance
            modalInstance.hide(); // Hide the modal
            this.$emit('age-confirmed'); // Notify parent component
        },
    },
    mounted() {
        // Automatically show the modal on mount
        const modalElement = document.getElementById('ageConfirmationModal');
        const modalInstance = new bootstrap.Modal(modalElement, {
            backdrop: 'static', // Prevent closing by clicking outside
            keyboard: false,    // Prevent closing with Escape key
        });
        if (localStorage.getItem('ageVerified') !== 'true') {
            modalInstance.show();
        }
    },
};
</script>

<style>
.modal-fullscreen {
    text-align: center;

}

.modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-title {
    text-align: center;
    width: 100%;
}

.modal-body {
    flex: none !important;
}
</style>